@use "./config/" as *;
@forward "./main.scss";
@forward './squad/athlete-listing';

.waf-squad {
    .waf-body {
        margin-top: -8rem;
    }
}
.waf-certificate {
    @extend %development-page-head-bg;
    .head-wrap {
        margin-block: 0;
    }
    .waf-head {
        &::before {
            content: unset;
        }
    }
    .form-title {
        @extend %title-50;
        @extend %mb-8;
    }
    .player {
        &-content-head {
            padding: var(--space-6);
            .title {
                @extend %neutral-100;
            }
        }
        &-item {
            justify-content: space-between;
            flex-direction: column;
            gap: var(--space-2);
        }
    }
}
@include mq(col-tablet) {
    .waf-certificate {
        .player {
            &-item {
                flex-direction: row;
                gap: var(--space-4);
            }
            &-name {
                margin-bottom: var(--space-0);
            }
            &-content-head {
                padding: var(--space-8);
                .title {
                    font-size: 2.4rem;
                    line-height: 3.3rem;
                }
            }
        }
    }
}
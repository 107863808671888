@use "../config/" as *;
.waf-squad {
  padding: 0 0 var(--space-20);
  margin-bottom: 0;
  @extend %page-head-blue-bg;
  @extend %common-input-swiper-btn;
  @extend %player-list;
  .waf-body {
    border-radius: unset;
    background-color: unset;
  }
  .form-wrapper,
  .player-content-wrapper,
  .search-body-wrapper {
    @extend %border-radius-m;
    @extend %bg-neutral-0;
  }
  .form-wrapper {
    @extend %p-6-4;
    @extend %mb-8;
  }
  .filter-section {
    @extend %mt-6;
    .dropdown-item {
      width: 100%;
    }
  }
  .player-content {
    &-wrapper {
      &:empty {
        padding-bottom: 0;
      }
      @extend %pb-10;
    }
    &-head {
      border-bottom: .1rem solid var(--neutral-20);
      @extend %p-8;
      .title {
        @extend %mb-2;
        @extend %title-50;
      }
      .filter-label {
        @extend %text-m;
      }
    }
    &-body {
      @extend %px-4;
      @extend %my-10;
    }
  }
  .search {
    &-body-wrapper {
      @extend %p-10-4;
      .player {
        &-info {
          @extend %mb-4;
        }
      }
      .title {
        @extend %body-mb;
        @extend %mb-4;
      }
      .meta {
        @extend %text-m;
        @extend %neutral-70;
      }
      .btn-link {
        width: 6.4rem;
        aspect-ratio: 1/1;
        .text {
          @extend %font-0;
          &:after {
            font-weight: 700;
            @include icon(arrow-down-tray, 24);
            @extend %primary-50;
            @extend %flex-fe-c;
          }
        }
      }
      em {
        font-style: normal;
      }
    }
    &-item {
      @include border(1, neutral-20, 10, bottom);
      @extend %py-4;
      @extend %flex;
      @extend %gap-2;
    }
    &-meta-list {
      grid-area: 2/1;
      flex-wrap: wrap;
      @extend %flex;
      @extend %gap-2;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    @extend %d-none;
  }
  .btn-close {
    @extend %d-none;
  }
}
@include mq(col-tablet) {
  .waf-squad {
    .form-wrapper {
      padding-inline: var(--space-10);
      border-radius: var(--border-radius-l);
      isolation: isolate;
    }
    .player-content {
      &-wrapper {
        border-radius: var(--border-radius-xl);
      }
      &-head .title {
        font-size: 3.2rem;
        line-height: 1.3;
        letter-spacing: -.064rem;
      }
      &-body {
        padding: var(--space-8) calc(var(--container-white-space) / 2) 0;
        margin-block: 0;
      }
    }
    .filter-section {
      margin-top: var(--space-8);
      &-wrapper {
        flex-wrap: wrap;
      }
      .head {
        height: unset;
        padding: 0;
        border-bottom: none;
        margin-bottom: var(--space-2);
        display: block;
        flex-basis: 100%;
        .label {
          font: 700 1.6rem/1 $font-primary;
        }
      }
      .btn-cancel {
        display: none;
      }
      .body {
        padding-left: 0;
        display: grid;
        flex-grow: 1;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
    .search {
      &-body-wrapper {
        padding: var(--space-8) calc(var(--container-white-space) / 2) 0;
        .title {
          font-size: 1.6rem;
        }
        .player-info {
          gap: unset;
          flex-direction: column;
        }
      }
      &-item {
        justify-content: space-between;
      }
    }
  }
}
@include mq(col-lg) {
  .waf-squad {
    .player {
      &-item-list {
        display: grid;
        gap: 0 var(--space-4);
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      &-content-wrapper {
        padding-bottom: var(--space-8);
      }
    }
    .pagination-wrap {
      margin-top: var(--space-18);
    }
    .search-body-wrapper {
      padding: var(--space-16);
    }
  }
}
@include mq(col-desktop) {
  .waf-squad .player-item-list {
    gap: 0 var(--space-6);
  }
}
@include mq(col-xl) {
  .waf-squad {
    .player-thumbnail {
      width: 9.6rem;
      height: 9.6rem;
    }
  }
}